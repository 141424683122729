@use "sass:math";
$spinner-size: 6rem;

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1000;
  opacity: 0;
  transition: opacity 0.5s;

  &.loading {
    z-index: 1000;
    opacity: 1;
  }
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  width: $spinner-size;
  height: $spinner-size;
  margin-left: -1 * math.div($spinner-size, 2);
  margin-top: -1 * math.div(-1 * $spinner-size, 2);
}
