.home {
  display: grid;
  height: 100%;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "alert alert alert alert"
    "user meals manWork resources"
    "link link notes notes";
}

.homeCard {
  align-items: center;
}

.home .homeButton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(:hover),
  &:disabled {
    background-color: #ffffff;
  }
}

.alert {
  .alertsWrapper {
    width: 100%;
  }

  composes: homeCard;
  grid-area: alert;
}

.user {
  composes: homeButton;
  grid-area: user;
}

.meals {
  composes: homeButton;
  grid-area: meals;
}

.manWork {
  composes: homeButton;
  grid-area: manWork;
}

.resources {
  composes: homeButton;
  grid-area: resources;
}

.link {
  composes: homeCard;
  grid-area: link;
}

.notes {
  composes: homeCard;
  grid-area: notes;
}
