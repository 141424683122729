@import "src/variables.shared";

$sidePanelSize: 15rem;
$sidePanelReducedSize: 4rem;

$sidePanelTransitionTime: 0.5s;

@include media-breakpoint-between(xs, sm) {
  .sidePanel {
    position: fixed;
    transition: all $sidePanelTransitionTime;
    z-index: $zindex-fixed;
    width: 100%;
  }

  .sidePanelOpen {
    left: 0;
  }

  .sidePanelClose {
    left: -100%;
  }
  .innerCloseButton {
    display: block;

    button {
      font-size: large;
    }
  }
}

@include media-breakpoint-between(md, lg) {
  .sidePanel {
    position: relative;
    width: $sidePanelSize;
    min-width: $sidePanelSize;
    max-width: $sidePanelSize;
    transition: all $sidePanelTransitionTime;
  }

  .sidePanelOpen {
    margin-left: 0;
  }

  .sidePanelClose {
    margin-left: $sidePanelReducedSize - $sidePanelSize;
  }

  .innerCloseButton {
    display: none;
  }
}
