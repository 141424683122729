@use "sass:math";
@import "../../ui/calendar/Calendar.shared";

.formHeader {
  display: block;
  width: 100%;
}

.status {
  font-size: math.div($cell-height, 3);
  line-height: $cell-height;
  text-align: right;
  padding: 0;
  margin: 0;
}
