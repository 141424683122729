@import "src/variables.shared";

.formHeader {
  margin-top: 2rem;
  display: block;
  width: 100%;
}

.reservationsList {
  list-style: none;
}

.entry {
  line-height: 2rem;
  font-size: larger;

  .name {
  }

  .status {
    margin-left: 1rem;
    font-size: x-large;
    .confirmIcon {
      //color: $success;
    }

    .absenceIcon {
      //color: $error;
    }
  }
}
