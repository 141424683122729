@import "src/variables.shared";

.navToggler {
  position: relative;
  display: block;
  width: 34px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  background-color: transparent;
  border: 0 solid transparent;

  // hamburger icon in CSS
  span,
  span::before,
  span::after {
    position: absolute;
    display: inline-block;
    height: 3px;
    width: 24px;
    background: theme-color("gray");
  }

  // line in the center
  span {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -2px;
    @include transition(background 0.2s);
  }

  span::before,
  span::after {
    /* other 2 lines */
    content: "";
    right: 0;
    transform-origin: 0 50%;
    @include transition(transform 0.2s);
  }

  span::before {
    /* menu icon top line */
    top: -6px;
  }

  span::after {
    /* menu icon bottom line */
    top: 6px;
  }

  &.isOpen {
    span {
      /* hide line in the center */
      background: rgba(theme-color("gray"), 0);
    }

    span::before,
    span::after {
      /* keep visible other 2 lines */
      background: rgba(theme-color("gray"), 1);
    }

    span::before {
      transform: translateX(4px) translateY(-3px) rotate(45deg);
    }

    span::after {
      transform: translateX(4px) translateY(2px) rotate(-45deg);
    }
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
