@import "src/variables.shared";

// TODO: questa var serve col nuovo layout?
$sidePanelTransitionTime: 0.5s;

.stretch {
  align-items: stretch;
  display: flex;
}

.content {
  width: 100%;
  transition: all $sidePanelTransitionTime;
}

.pageContent {
  padding: 2rem !important;
}

.mainContent {
  display: flex;
}

.contentWrapper {
  flex: 1 1 auto;
  padding: calc(#{$navbar-height} + #{$spacer}) $spacer $spacer;
  overflow: auto;
}

.contentTitle {
  display: flex;
  border-bottom: 1px solid theme-color("gray");
  padding-bottom: map-get($spacers, 1);
  margin-bottom: map-get($spacers, 4);
}

.pageTitle {
  composes: contentTitle;
}

.sectionTitle {
  composes: contentTitle;
}
