@import "src/variables.shared";

.menu {
}

.menuExtended {
}

.menuRestricted {
}

.menuItemText {
  display: inline-block;
  width: auto;
  margin: auto;
  text-align: center;
  font-size: 1.2rem;
}

.menuItem {
  text-align: center;
  font-variant: all-small-caps;
  line-height: 2rem;
  font-size: 1.2rem;

  & * {
    color: map-get($theme-colors, "light");
  }

  &:hover * {
    color: map-get($theme-colors, "secondary");
  }
}

.active * {
  color: map-get($theme-colors, "secondary");
}

.subMenu {
}

.subMenuTitle {
  font-variant: all-small-caps;
  cursor: pointer;
}

.subMenuContent {
  background: rgba(0, 0, 0, 0.125);
}

.subMenuIcon {
  float: right;
  text-align: center;
  margin-right: 1rem;
  display: block;
  width: 1rem;
}
