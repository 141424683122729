@use "sass:math";
@import "src/variables.shared";
@import "Calendar.shared";


.calendar {
  width: 100%;
  border: 1px solid $border-color;

  .thDay {
    text-align: center;
    padding: 0.25rem 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-weight: bold;
  }

  .row {
    height: $cell-height;

    .cell {
      font-size: math.div($cell-height, 5);
      padding: 0;
      border: 1px solid $border-color;

      .dayContainer {
        display: inline-block;

        .dayNumber {
          padding: math.div($cell-height, 20);
          margin: 0;
          width: auto;
        }
      }

      .childrenContainer {
        display: inline-block;
        float: right;
        margin-right: 1rem;
      }
    }

    .disabled {
      background-color: $disabled-color;
    }

    .today {
      background-color: theme-color("primary");
      color: $white;

      .dayNumber {
        //font-size: $cell-height/4;
        //box-shadow: 0 0 10px 0 $today-color;
        //border-radius: 50%;
      }
    }

    .selected {
      //background-color: theme-color-level("secondary", $table-bg-level);
      background-color: theme-color("secondary");
      color: $white;

      .dayNumber {
        //font-size: $cell-height/4;
        //box-shadow: 0 0 10px 0 $today-color;
        //border-radius: 50%;
      }
    }
  }
}

.buttonNext,
.buttonPrevious {
  box-shadow: none !important;
}
