@import "src/variables.shared";

.alertSandbox {
  position: absolute;
  top: calc(1rem + #{$navbar-height});
  left: 20%;
  right: 20%;
  margin: auto;
  align-items: center;
  z-index: 1000;
}
