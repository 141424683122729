@use "sass:math";
@import "src/variables.shared";


.sideNav {
  background: darken($menu-color, 10);
  position: fixed;
  z-index: 4; // > 3 che è il bottone della paginazione
  left: 0;
  top: 0;
  width: 100%;
  padding: calc(#{$navbar-height} + #{$spacer}) 0 $spacer;
  visibility: hidden;
  opacity: 0;
  max-height: 100vh;
  overflow: hidden;
  @include transition(opacity 0.2s 0s, visibility 0s 0.2s);

  > div {
    display: flex;
    flex-direction: column;
    position: sticky;
  }

  :global(.dropdown-menu) {
    position: static;
    float: none;
    background-color: #000;
    border: none;
    border-radius: 0;
  }

  &.navIsVisible {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    @include transition(opacity 0.2s 0s, visibility 0s 0s);
    max-height: none;
    box-shadow: 0 2px 10px rgba(#000, 0.2);
  }

  .navItem {
    border-bottom: 1px solid $menu-color;

    a:global(.active) {
      box-shadow: inset 3px 0 0 theme-color("primary");
      background: darken($menu-color, 5);
    }

    &:global(.show) {
      a:after {
        transform: rotate(180deg);
      }
    }

    a {
      display: flex;
      color: theme-color("light");
      align-items: center;
      padding: $nav-link-padding-y $nav-link-padding-x;

      &:after {
        margin-left: auto;
      }

      &:hover {
        background: $menu-color;
        color: theme-color("secondary");
        box-shadow: inset 3px 0 0 theme-color("secondary");

        svg {
          color: theme-color("light");
        }
      }

      svg {
        margin-right: $spacer;
        opacity: 0.5;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    position: relative;
    top: auto;
    width: $side-nav-sm-width;
    padding-top: $navbar-height;
    flex: 0 0 auto;
    min-height: 100vh;

    // reset style
    visibility: visible;
    opacity: 1;
    overflow: visible;
    max-height: none;

    > div {
      top: $navbar-height;
      min-height: calc(100vh - #{$navbar-height} - #{$spacer});
    }

    &.nav-is-visible {
      box-shadow: none;
    }

    .navItem {
      &:global(.show) {
        a:after {
          transform: rotate(180deg);
        }
      }

      a:global(.dropdown-toggle) {
        padding-right: calc(#{$nav-link-padding-x} + #{$caret-width});
        padding-left: calc(#{$nav-link-padding-x} + #{$caret-width});
      }

      :global(.dropdown-menu) {
        margin-left: 0;
      }

      > a {
        flex-direction: column;
        align-items: center;
        padding-top: $nav-link-padding-y * 2;
        padding-bottom: $nav-link-padding-x;

        svg {
          margin-right: 0;
          margin-bottom: math.div($spacer, 2);
        }
      }

      a {
        font-size: 80%;

        &:after {
          position: absolute;
          right: 0.5rem;
          top: calc(50% - #{$caret-width});
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: calc(#{$navbar-height} + #{$spacer});
    width: $side-nav-lg-width;

    > div {
      top: calc(#{$navbar-height} + #{$spacer});
      min-height: calc(
        100vh - calc(#{$navbar-height} + #{$spacer}) - #{$spacer}
      );
    }

    .navItem {
      a:global(.dropdown-toggle) {
        padding-left: $nav-link-padding-x;
      }

      > a:global(.active) {
        box-shadow: none;
        background-color: #000;
        pointer-events: none;

        &:after {
          display: none;
        }

        + :global(.dropdown-menu) {
          display: block;
          position: static;
        }
      }

      a {
        flex-direction: row;
        font-size: 100%;

        svg {
          margin-right: math.div($spacer, 2);
          margin-bottom: 0;
        }
      }
    }
  }
}

.footerNav {
  color: theme-color("light");
  align-items: center;
  padding: $nav-link-padding-y $nav-link-padding-x;

  margin-top: auto;
}
