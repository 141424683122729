@import "src/variables.shared";

.resetPasswordPage {
  width: 100vw;
  height: 100vh;
  background-color: theme-color("dark");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacer;

  .resetPassword {
    text-align: right;
    margin-bottom: 0;

    a {
      padding: 0;
    }
  }
}
