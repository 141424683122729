.formHeader {
  display: block;
  width: 100%;

  &.formMargin {
    margin-top: 1rem;
  }
}

.deleteButton {
  margin-left: 1rem;
}

.createButton {
  margin-top: 1rem;

  button {
    width: 100%;
  }
}
