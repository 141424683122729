@import "src/variables.shared";

.toggleSidePanelButton {
  font-size: large;
  box-shadow: none !important;
}

.navBar {
  background-color: $menu-color;
}

a.navbarBrand {
  padding: 0;

  display: inline-flex;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    width: $side-nav-sm-width - 30px;
  }

  @include media-breakpoint-up(lg) {
    width: $side-nav-lg-width - 30px;
  }
}
