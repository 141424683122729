@use "sass:math";
@import "src/variables.shared";

$cell-border-color: #cccccc;

.wrapper {
  position: relative;
  display: flex;
}

.navigation {
  display: flex;
  justify-content: end;
  gap: $spacer;
  margin-bottom: math.div($spacer, 2);
}

.resources {
  border: solid $cell-border-color;
  border-width: 0 0 1px 1px;

  > div {
    &.heading {
      height: calc(var(--cell-height) * 2);
    }

    border: solid $cell-border-color;
    border-width: 1px 1px 0 0;
    height: var(--cell-height);
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-bottom-width: 1px;
    }
  }
}

.mainGrid {
  position: relative;
  border: solid $cell-border-color;
  border-width: 0 0 1px 1px;
  display: grid;
  width: 100%;
  overflow: auto;
  align-content: center;
}

.gridCell {
  border: solid $cell-border-color;
  border-width: 1px 1px 0 0;
  height: var(--cell-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  composes: gridCell;
  overflow: hidden;
  position: relative;
  text-transform: capitalize;
  > * {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.singleCell {
  composes: gridCell;
  min-width: var(--min-cell-width);
}

.today {
  background-color: lighten($primary, 67);
}

.now {
  background: $primary;
  bottom: 0;
  left: var(--now-position);
  position: absolute;
  top: 70px;
  width: 1px;
  z-index: 1;
}
