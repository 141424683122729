@import "src/variables.shared";

.loginPage {
  width: 100vw;
  height: 100vh;
  background-color: theme-color("dark");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacer;

  .resetPassword {
    text-align: right;
    margin-bottom: 0;

    a {
      padding: 0;
    }
  }

  .loginFormWrapper {
    max-width: 350px;
  }
}

.logoutButton {
  box-shadow: none !important;
}
