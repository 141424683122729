@import "src/variables.shared";

.event {
  position: absolute;
  display: flex;
  justify-content: center;
  height: var(--cell-height);
  padding: 0;
  border: none;
  background: transparent;
  z-index: 1;
  transition: z-index 2s 0s, transform 0.85s ease;

  &:hover,
  &:focus {
    z-index: 2;
    transition: z-index 0s, transform 0.25s ease;
    transform: translateX(var(--translate-needed));

    > div {
      width: max(var(--label-natural-width), 100%);
      transition: width 0.25s ease, padding 0.25s ease;
      padding: 4px;
    }
  }

  > div {
    border-radius: 3px;
    border: 1px solid darken($primary, 10);
    background: $primary;
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px min(4px, (25% - 1px));
    transition: width 0.85s ease, padding 0.85s ease;

    box-shadow: inset 0 3px 0 0 var(--event-color);

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
