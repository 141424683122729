@import "../node_modules/bootstrap/scss/functions.scss";
@import "../node_modules/bootstrap/scss/mixins.scss";
//@import "../node_modules/bootstrap/scss/variables";

// Color system
$dark: #343a40;
$primary: #005193;
$secondary: #f49b0a;
$gray: #adb5bd;

$theme-colors: (
  "gray": $gray,
);
// Navbar
$navbar-brand-padding-y: 0.3125rem;
$navbar-padding-y: 0.5rem;

// Body
$body-bg: #eeeeee;

// Typography
$font-family-sans-serif: "Roboto", sans-serif;

// Custom
$navbar-height: calc(
  30px + #{2 * $navbar-brand-padding-y} + #{2 * $navbar-padding-y}
);
$menu-color: $dark;
$side-nav-lg-width: 250px;
$side-nav-sm-width: 120px;

@import "../node_modules/bootstrap/scss/variables";
